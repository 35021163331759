import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { UserDocuments } from "src/models/applicant";
import { ApplicationForm } from "src/models/applications";
import axios from '../axios';

export const getClientApplicationRequests = createAsyncThunk(
  'application/getClientApplicationRequests',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/applications/request`);
      return fulfillWithValue(data.requests);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteRequestById = createAsyncThunk(
  'application/deleteRequestById',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/applications/request/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const uploadUserRequestDocument = createAsyncThunk(
  'application/uploadUserRequestDocument',
  async ({ user_id, docUpload }: { user_id: number, docUpload: UserDocuments }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('document_name', docUpload.document_name);
      formData.append('file', docUpload.file);
      formData.append('user_id', user_id.toString());
      const { data } = await axios.post(`/admin/applications/request/documents`, formData, {
        headers: { "Content-type": "multipart/form-data" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllJobs = createAsyncThunk(
  'application/getAllJobs',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/applications/jobs`);
      return fulfillWithValue(data.jobs);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyJobApplication = createAsyncThunk(
  'application/verifyJobApplication',
  async (agent_id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/applications/job/accept/${agent_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const rejectJobApplication = createAsyncThunk(
  'application/rejectJobApplication',
  async ({ reason, id }: { reason: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/applications/job/reject/${id}`, { reason });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const saveApplicationForm = createAsyncThunk(
  'application/saveApplicationForm',
  async (form: ApplicationForm, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/applications/form`, form);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateApplicationForm = createAsyncThunk(
  'application/updateApplicationForm',
  async (form: ApplicationForm, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/applications/form/${form.id}`, form);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteApplicationForm = createAsyncThunk(
  'application/deleteApplicationForm',
  async (form_id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/applications/form/${form_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const changeApplicationFormActiveness = createAsyncThunk(
  'application/changeApplicationFormActiveness',
  async ({ is_active, id }: { is_active: boolean, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/applications/form/activeness/${id}`, { is_active });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllApplicationForms = createAsyncThunk(
  'application/getAllApplicationForms',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/applications/form`);
      return fulfillWithValue(data.forms);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getApplicationFormById = createAsyncThunk(
  'application/getApplicationFormById',
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/applications/form/${id}`);
      return fulfillWithValue(data.form);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);