import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import {
  changeApplicationFormActiveness,
  deleteApplicationForm,
  deleteRequestById,
  getAllApplicationForms,
  getAllJobs,
  getApplicationFormById,
  getClientApplicationRequests, rejectJobApplication, saveApplicationForm, updateApplicationForm, uploadUserRequestDocument, verifyJobApplication
} from './applicationService';

const initialState: ApplicationState = {
  message: '',
  status: null,
  forms: [],
  requests: [],
  jobs: []
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    clearApplicationState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveApplicationForm.pending, asyncIsPending)
    builder.addCase(saveApplicationForm.rejected, asyncIsRejected)
    builder.addCase(saveApplicationForm.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteRequestById.pending, asyncIsPending)
    builder.addCase(deleteRequestById.rejected, asyncIsRejected)
    builder.addCase(deleteRequestById.fulfilled, asyncIsFulfilled)
    builder.addCase(uploadUserRequestDocument.pending, asyncIsPending)
    builder.addCase(uploadUserRequestDocument.rejected, asyncIsRejected)
    builder.addCase(uploadUserRequestDocument.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteApplicationForm.pending, asyncIsPending)
    builder.addCase(deleteApplicationForm.rejected, asyncIsRejected)
    builder.addCase(deleteApplicationForm.fulfilled, asyncIsFulfilled)
    builder.addCase(changeApplicationFormActiveness.pending, asyncIsPending)
    builder.addCase(changeApplicationFormActiveness.rejected, asyncIsRejected)
    builder.addCase(changeApplicationFormActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(updateApplicationForm.pending, asyncIsPending)
    builder.addCase(updateApplicationForm.rejected, asyncIsRejected)
    builder.addCase(updateApplicationForm.fulfilled, asyncIsFulfilled)
    builder.addCase(verifyJobApplication.pending, asyncIsPending)
    builder.addCase(verifyJobApplication.rejected, asyncIsRejected)
    builder.addCase(verifyJobApplication.fulfilled, asyncIsFulfilled)
    builder.addCase(rejectJobApplication.pending, asyncIsPending)
    builder.addCase(rejectJobApplication.rejected, asyncIsRejected)
    builder.addCase(rejectJobApplication.fulfilled, asyncIsFulfilled)
    builder.addCase(getClientApplicationRequests.pending, asyncIsPending)
    builder.addCase(getClientApplicationRequests.rejected, asyncIsRejected)
    builder.addCase(getClientApplicationRequests.fulfilled, (state, action) => {
      state.status = null;
      state.requests = action.payload;
    })
    builder.addCase(getAllJobs.pending, asyncIsPending)
    builder.addCase(getAllJobs.rejected, asyncIsRejected)
    builder.addCase(getAllJobs.fulfilled, (state, action) => {
      state.status = null;
      state.jobs = action.payload;
    })
    builder.addCase(getAllApplicationForms.pending, asyncIsPending)
    builder.addCase(getAllApplicationForms.rejected, asyncIsRejected)
    builder.addCase(getAllApplicationForms.fulfilled, (state, action) => {
      state.status = null;
      state.forms = action.payload;
    })
    builder.addCase(getApplicationFormById.pending, asyncIsPending)
    builder.addCase(getApplicationFormById.rejected, asyncIsRejected)
    builder.addCase(getApplicationFormById.fulfilled, (state, action) => {
      state.status = null;
      state.forms = [action.payload];
    })
  }
});

export const { clearApplicationState } = applicationSlice.actions;
export default applicationSlice.reducer;