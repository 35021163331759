import { createSlice } from '@reduxjs/toolkit';
import { ConfigState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { addCountry, addJobTag, addService, agencyOfferedServices, deleteCountry, deleteJobTag, deleteOfferedServices, getAllCountries, getJobTags, getSystemConfig, getVisaTypes, updateBlogCategories, updateDocument, updateServiceActiveness } from './configService';

const initialState: ConfigState = {
  message: '',
  services: [],
  countries: [],
  tags: [],
  status: null,
  admin_maintance_mode: false,
  agent_maintenance_mode: false,
  client_maintenance_mode: false,
  accepted_documents: [],
  blog_categories: [],
  dollar_rate: 0,
  visa_types: [
    {
      visa_name: 'Tourist Visa',
      id: 1,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Business Visa',
      id: 2,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Student Visa',
      id: 3,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Work Visa',
      id: 4,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    }
  ]
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    clearConfigState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteCountry.pending, asyncIsPending)
    builder.addCase(deleteCountry.rejected, asyncIsRejected)
    builder.addCase(deleteCountry.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteJobTag.pending, asyncIsPending)
    builder.addCase(deleteJobTag.rejected, asyncIsRejected)
    builder.addCase(deleteJobTag.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteOfferedServices.pending, asyncIsPending)
    builder.addCase(deleteOfferedServices.rejected, asyncIsRejected)
    builder.addCase(deleteOfferedServices.fulfilled, asyncIsFulfilled)
    builder.addCase(addCountry.pending, asyncIsPending)
    builder.addCase(addCountry.rejected, asyncIsRejected)
    builder.addCase(addCountry.fulfilled, asyncIsFulfilled)
    builder.addCase(addJobTag.pending, asyncIsPending)
    builder.addCase(addJobTag.rejected, asyncIsRejected)
    builder.addCase(addJobTag.fulfilled, asyncIsFulfilled)
    builder.addCase(updateDocument.pending, asyncIsPending)
    builder.addCase(updateDocument.rejected, asyncIsRejected)
    builder.addCase(updateDocument.fulfilled, asyncIsFulfilled)
    builder.addCase(updateBlogCategories.pending, asyncIsPending)
    builder.addCase(updateBlogCategories.rejected, asyncIsRejected)
    builder.addCase(updateBlogCategories.fulfilled, asyncIsFulfilled)
    builder.addCase(updateServiceActiveness.pending, asyncIsPending)
    builder.addCase(updateServiceActiveness.rejected, asyncIsRejected)
    builder.addCase(updateServiceActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(addService.pending, asyncIsPending)
    builder.addCase(addService.rejected, asyncIsRejected)
    builder.addCase(addService.fulfilled, asyncIsFulfilled)
    builder.addCase(agencyOfferedServices.pending, asyncIsPending)
    builder.addCase(agencyOfferedServices.rejected, asyncIsRejected)
    builder.addCase(agencyOfferedServices.fulfilled, (state, action) => {
      state.status = null;
      state.services = action.payload;
    })
    builder.addCase(getVisaTypes.pending, asyncIsPending)
    builder.addCase(getVisaTypes.rejected, asyncIsRejected)
    builder.addCase(getVisaTypes.fulfilled, (state, action) => {
      state.status = null;
      state.visa_types = action.payload;
    })
    builder.addCase(getAllCountries.pending, asyncIsPending)
    builder.addCase(getAllCountries.rejected, asyncIsRejected)
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.status = null;
      state.countries = action.payload;
    })
    builder.addCase(getJobTags.pending, asyncIsPending)
    builder.addCase(getJobTags.rejected, asyncIsRejected)
    builder.addCase(getJobTags.fulfilled, (state, action) => {
      state.status = null;
      state.tags = action.payload;
    })
    builder.addCase(getSystemConfig.pending, asyncIsPending)
    builder.addCase(getSystemConfig.rejected, asyncIsRejected)
    builder.addCase(getSystemConfig.fulfilled, (state, action) => {
      state.status = null;
      state.dollar_rate = action.payload.dollar_rate;
      state.accepted_documents = action.payload.accepted_documents;
      state.blog_categories = action.payload.blog_categories;
      state.client_maintenance_mode = action.payload.client_maintenance_mode;
      state.admin_maintance_mode = action.payload.admin_maintenance_mode;
      state.agent_maintenance_mode = action.payload.agent_maintenance_mode;
    })
  }
});

export const { clearConfigState } = configSlice.actions;
export default configSlice.reducer;