import { createSlice } from '@reduxjs/toolkit';
import { AgentStats } from 'src/models/agent';
import { UsersState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { addAdmin, agentActiveness, clientActiveness, deleteAgentById, deleteClientById, deleteIssueById, emailUser, getAgentStats, getAllAdmins, getAllAgents, getAllClients, getAllIssues, getAllWellbeingMsgs, markIssueAsResolved, rejectAgent, replyIssueById, replyWellbingMsg, requestAdditionFiles, subscribeUserToPackage, verifyAgent } from './usersServices';

const initialState: UsersState = {
  message: '',
  status: null,
  agents: [],
  wellbeing: [],
  clients: [],
  admins: [],
  agent_stats: {} as AgentStats,
  issues: []
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsersState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAdmin.pending, asyncIsPending)
    builder.addCase(addAdmin.rejected, asyncIsRejected)
    builder.addCase(addAdmin.fulfilled, asyncIsFulfilled)
    builder.addCase(replyIssueById.pending, asyncIsPending)
    builder.addCase(replyIssueById.rejected, asyncIsRejected)
    builder.addCase(replyIssueById.fulfilled, asyncIsFulfilled)
    builder.addCase(replyWellbingMsg.pending, asyncIsPending)
    builder.addCase(replyWellbingMsg.rejected, asyncIsRejected)
    builder.addCase(replyWellbingMsg.fulfilled, asyncIsFulfilled)
    builder.addCase(markIssueAsResolved.pending, asyncIsPending)
    builder.addCase(markIssueAsResolved.rejected, asyncIsRejected)
    builder.addCase(markIssueAsResolved.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteIssueById.pending, asyncIsPending)
    builder.addCase(deleteIssueById.rejected, asyncIsRejected)
    builder.addCase(deleteIssueById.fulfilled, asyncIsFulfilled)
    builder.addCase(subscribeUserToPackage.pending, asyncIsPending)
    builder.addCase(subscribeUserToPackage.rejected, asyncIsRejected)
    builder.addCase(subscribeUserToPackage.fulfilled, asyncIsFulfilled)
    builder.addCase(requestAdditionFiles.pending, asyncIsPending)
    builder.addCase(requestAdditionFiles.rejected, asyncIsRejected)
    builder.addCase(requestAdditionFiles.fulfilled, asyncIsFulfilled)
    builder.addCase(agentActiveness.pending, asyncIsPending)
    builder.addCase(agentActiveness.rejected, asyncIsRejected)
    builder.addCase(agentActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(clientActiveness.pending, asyncIsPending)
    builder.addCase(clientActiveness.rejected, asyncIsRejected)
    builder.addCase(clientActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(verifyAgent.pending, asyncIsPending)
    builder.addCase(verifyAgent.rejected, asyncIsRejected)
    builder.addCase(verifyAgent.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteClientById.pending, asyncIsPending)
    builder.addCase(deleteClientById.rejected, asyncIsRejected)
    builder.addCase(deleteClientById.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteAgentById.pending, asyncIsPending)
    builder.addCase(deleteAgentById.rejected, asyncIsRejected)
    builder.addCase(deleteAgentById.fulfilled, asyncIsFulfilled)
    builder.addCase(rejectAgent.pending, asyncIsPending)
    builder.addCase(rejectAgent.rejected, asyncIsRejected)
    builder.addCase(rejectAgent.fulfilled, asyncIsFulfilled)
    builder.addCase(emailUser.pending, asyncIsPending)
    builder.addCase(emailUser.rejected, asyncIsRejected)
    builder.addCase(emailUser.fulfilled, asyncIsFulfilled)
    builder.addCase(getAllAgents.pending, asyncIsPending)
    builder.addCase(getAllAgents.rejected, asyncIsRejected)
    builder.addCase(getAllAgents.fulfilled, (state, action) => {
      state.status = null;
      state.agents = action.payload;
    })
    builder.addCase(getAllIssues.pending, asyncIsPending)
    builder.addCase(getAllIssues.rejected, asyncIsRejected)
    builder.addCase(getAllIssues.fulfilled, (state, action) => {
      state.status = null;
      state.issues = action.payload;
    })
    builder.addCase(getAllWellbeingMsgs.pending, asyncIsPending)
    builder.addCase(getAllWellbeingMsgs.rejected, asyncIsRejected)
    builder.addCase(getAllWellbeingMsgs.fulfilled, (state, action) => {
      state.status = null;
      state.wellbeing = action.payload;
    })
    builder.addCase(getAllClients.pending, asyncIsPending)
    builder.addCase(getAllClients.rejected, asyncIsRejected)
    builder.addCase(getAllClients.fulfilled, (state, action) => {
      state.status = null;
      state.clients = action.payload;
    })
    builder.addCase(getAllAdmins.pending, asyncIsPending)
    builder.addCase(getAllAdmins.rejected, asyncIsRejected)
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.status = null;
      state.admins = action.payload;
    })
    builder.addCase(getAgentStats.pending, asyncIsPending)
    builder.addCase(getAgentStats.rejected, asyncIsRejected)
    builder.addCase(getAgentStats.fulfilled, (state, action) => {
      state.status = null;
      state.agent_stats = action.payload;
    })
  }
});

export const { clearUsersState } = usersSlice.actions;
export default usersSlice.reducer;